import axios from 'axios';
// import store from '../store/index'
import {Toast, Notify, Dialog} from 'vant';

// axios.defaults.headers["Content-Type"] = "application/json";
const ajax = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 26000,
    // headers: {'Content-Type': 'application/json;charset=UTF-8'}
});

ajax.interceptors.request.use(config => {
    //请求前

    if(!config.headers.NO_LOADING){
      Toast.loading({
        message: '加载中...',
        // overlay : true,
        forbidClick: true,
        duration : 0,
      });
    }

    return config;
}, error => {

    Promise.reject(error);
});

ajax.interceptors.response.use(

  response => {
      // loading 延长1s
      let timeout = null;
      if(timeout){
          clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
          Toast.clear();
      }, 700)

    const res = response.data
    //请求后
    if(res.code === '0000' && res.data !== false){
      return res
    }else{
      // Notify({ type: 'danger', message: res.message });

      if(res.code == '0100'){
         Toast({message:res.message,duration:800})
        // sessionStorage.clear();
        localStorage.clear();
        setTimeout(()=>{
          location.replace(location.origin + '/login');
        },1000)
        return;
      }
      if(res.code == '9901'){//护士绑定特殊处理
          Dialog.alert({
              title:'温馨提示',
              message:res.message,
              theme:'round-button',
              confirmButtonColor:'rgb(0, 194, 145)'
          })
          return;
      }

      Toast({message:res.message,duration:1500});
      return Promise.reject(res)
    }
  },
  error => {
    Toast.clear();
    const errorResponse = error.response;
    Notify({ type: 'danger', message: res.message });
    return Promise.reject(errorResponse)
  });

export default ajax
