<template>
  <!-- <router-view >

  </router-view> -->

  <router-view v-slot="{ Component }">
    <transition name="van-fade" >
      <keep-alive :include='keepAlive'>
        <component :is="Component"/>
      </keep-alive>
    </transition >

  </router-view>

  <van-row class="bottomBar"  v-if="route.meta.footShow">
    <van-col span="6" @click="goHomePage()" :class='{activeFooter:name == "home"}'>
      <img :src="name == 'home'?'https://static.yihu365.cn/img/h5Img/assets/img/footer/home1.png':'https://static.yihu365.cn/img/h5Img/assets/img/footer/home.png'" alt="" />
      首页
    </van-col>
    <van-col span="6" @click="router.push({ path: '/shop' })" :class='{activeFooter:name == "shop"}'>
      <img :src="name == 'shop'?'https://static.yihu365.cn/img/h5Img/assets/img/footer/shop.png':'https://static.yihu365.cn/img/h5Img/assets/img/footer/shop.png'" alt="" />
      商城
    </van-col>
    <van-col span="6" @click="router.push({ path: '/community/communityList' })" :class='{activeFooter:name == "communityList"}'>
      <img :src="name == 'communityList'?'https://static.yihu365.cn/img/h5Img/assets/img/footer/order1.png':'https://static.yihu365.cn/img/h5Img/assets/img/footer/order.png'" alt="" />
      小护
    </van-col>
    <van-col span="6" @click="router.push({ path: '/my' })" :class='{activeFooter:name == "my"}'>
      <img :src="name == 'my'?'https://static.yihu365.cn/img/h5Img/assets/img/footer/my1.png':'https://static.yihu365.cn/img/h5Img/assets/img/footer/my.png'" alt="" />
      我的
    </van-col>
  </van-row>
</template>

<script>

import { computed} from 'vue'
import { useRouter, useRoute } from 'vue-router'


export default {
  name: 'App',
  setup() {

    const router = useRouter()
    const route = useRoute()

    let name = computed(() => {
      return route.name
    })
    const keepAlive = [];


    const goHomePage = () => {
      const jumpMark = sessionStorage.getItem('jumpMark') //获取渠道
        if(jumpMark == '80000001111'){//太平保险
            router.push({ path: '/homeTaiping' })
        }
        else if(jumpMark == '80000001112'){ //疯狂体育
            router.push({ path: '/homeCrazySports' })
        }
        else if(jumpMark == '80000001113'){ //自己渠道
          router.push({ path: '/homeCrazySports2' })
        }
        else{
            router.push({ path: '/' })
        }

    }
    return {
      keepAlive,
      name,
      route,
      router,
      goHomePage
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
  html,
  body {
    width: 100%;
    height: 100%;
  }
  @font-face {
    font-family: Alibaba1;
    src: url('https://static.yihu365.cn/img/wx-uni/font/Alibaba_PuHuiTi_2.0_45_Light_45_Light.ttf')
  }
  #app {
    width: 100%;
    height: 100%;
    //font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
    //font-family: Alibaba1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background: #fff;
  }
  .activeFooter{
    color:#00C291!important;
  }
  .bottomBar {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    height: 108px;
    box-shadow: 0px 0px 20px 20px #f5f5f5;
  }

  .bottomBar .van-col--8 {
    height: 100%;
    text-align: center;
    font-size: 20px;
    padding-top: 17px;
  }
  .bottomBar .van-col--6 {
    height: 100%;
    text-align: center;
    font-size: 20px;
    padding-top: 17px;
  }
  .bottomBar img {
    width: 44px;
    margin: 0 auto;
    display: block;
    margin-bottom:2px;
  }

  @-webkit-keyframes van-fade-in{
    from{opacity:0}
    to{opacity:1}
  }
  @keyframes van-fade-in{
    from{opacity:0}
    to{opacity:1}
  }
  @-webkit-keyframes van-fade-out{
    from{opacity:1}
    to{opacity:0}
  }
  @keyframes van-fade-out{
    from{opacity:1}
    to{opacity:0}
  }

  @-webkit-keyframes van-rotate{
    from{-webkit-transform:rotate(0);transform:rotate(0)}
    to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}
    }
  @keyframes van-rotate{
    from{-webkit-transform:rotate(0);transform:rotate(0)}
    to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}
  }

  .van-fade-enter-active{
    -webkit-animation:.3s van-fade-in both ease-out!important;
    animation:.3s van-fade-in both ease-out!important
  }
  .van-fade-leave-active{
    -webkit-animation:.05s van-fade-out both ease-in!important;
    animation:.05s van-fade-out both ease-in!important
  }
</style>
